import styled from 'styled-components';
import React , {useContext} from 'react'
import pdfIcon from '@images/pdf-icon.png'
import Header from "@common/menus/header"
import { FirebaseContext } from '@components/Firebase'
import BackToAppButton from "@common/BackToAppButton"
import { useTranslation } from 'react-i18next';
const data = {
  en:
    [
      {
        title:'Sweet Dreams',
        date:'1st April, 2022',
        url:`https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FpressReleases%2FSweet%20Dreams%20by%20Audiodesires%20(2nd%20Birthday)%20(1).pdf?alt=media&token=1ddbb463-2bcb-410f-ac73-dcaad49ce2ce`,
      },
      {
        title:'Valentines Day',
        date:'7th February, 2022',
        url:`https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FpressReleases%2FValentine's%20Day%20Press%20Release%2007_02_2022.pdf?alt=media&token=efc20704-7f6e-468f-bbbc-8c57f318c368`,
      },
      {
        title:'2021 Recap',
        date:'20th December, 2021',
        url:`https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FpressReleases%2FAudiodesires%202021%20in%20Review%20(1).pdf?alt=media&token=a5e71ba6-5761-44b3-a0a7-149856594533`,
      },
      {
        title:'New Reader Function',
        date:'30th November, 2021',
        url:`https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FpressReleases%2F30_11_2021%20PR%20Audiodesires%20-%20Reader%20Function.pdf?alt=media&token=009c84af-98c7-4f12-bc59-9df46bb4313e`,
      },
    ],
  de:
    [
      {
        title:'Süße Träume und Orgasmen',
        date:'1st April, 2022',
        url:`https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FpressReleases%2FPM_Audiodesires_Orgasmen%20%26%20Su%CC%88%C3%9Fe%20Tra%CC%88ume%20(1).pdf?alt=media&token=64b71698-c8a4-4fcf-9181-760019621f06`,
      },
      {
        title:'Valentinstag, Corona & Sex',
        date:'7th February, 2022',
        url:`https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FpressReleases%2FPM_Audiodesires_Valentinstag%2C%20Corona%20%26%20Sex.pdf?alt=media&token=3a1bde31-a9f0-4b5d-a935-c4b5ddc4cef0`,
      },
      {
        title:'2021 Jahresrückblick',
        date:'20th December, 2021',
        url:`https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FpressReleases%2FPM_Audiodesires_Jahresru%CC%88ckblick%202021.pdf?alt=media&token=0728897f-4b58-43da-9fbf-167d8b736392`,
      },
      {
        title:'Jetzt auch auf Deutsch',
        date:'30th November, 2021',
        url:`https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FpressReleases%2FAudiodesires%20-%20Pressemeldung%20-%2020.%20Juli%202021%20-%20Jetzt%20auch%20auf%20Deutsch.pdf?alt=media&token=595ab42f-1f81-4825-a99e-cdbbae1ec279`,
      },
    ],
  es:
    [
      {
        title:'Felices sueños de Audiodesires (2º cumpleaños)',
        date:'1 de abril, 2022',
        url:`https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FpressReleases%2FFelices%20suen%CC%83os%20de%20Audiodesires%20(2%C2%BA%20cumplean%CC%83os).pdf?alt=media&token=866fa0ee-e9e0-4242-99dd-66907cc94dab`,
      },
      {
        title:'San valentín y el sexo enlos tiempos de covid',
        date:'7 de febrero, 2022',
        url:`https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FpressReleases%2FES_El%20amor%20en%20los%20tiempos%20del%20COVID.pdf?alt=media&token=5d7e41ea-102c-4a57-acd7-fd07a9ce621a`,
      },
      {
        title:'nueva función de lectura',
        date:'30 de Noviembre, 2021',
        url:`https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FpressReleases%2FNP_Audiodesires_Function%20de%20Lectura.pdf?alt=media&token=922b8e27-f0c8-4aa0-9585-349cc0f56b07`,
      },
      {
        title:'Primera plataforma de audio erótico en español e inglés',
        date:'11 de Noviembre, 2020',
        url:`https://firebasestorage.googleapis.com/v0/b/audiodesires-aab7f.appspot.com/o/manual_storage%2FpressReleases%2FAudiodesires%20-%20Nota%20de%20prensa%20-%20Nov%2011%2C%202020%20-%20Primera%20plataforma%20de%20audios%20ero%CC%81ticos%20en%20espan%CC%83ol%20e%20ingle%CC%81s.pdf?alt=media&token=e7fcd5ba-858f-4b43-b73a-cf36d2e29ce6`,
      },
    ]
}

const PressReleases = (props) => {
  const lang = props.pageContext.lang
  const {user} = useContext(FirebaseContext);
  const { t } = useTranslation("pages")

  return (
    <OuterWrapper>
      <Header boxLayout={false} playerMode={'small'} user={user} alwaysShow={true}/>
      <Wrapper>
      <h1>{t('pages:press-releases.title')}</h1>
        {data[lang].map( release =>(
          <a href={release.url} target='_blank' rel="noreferrer">
            <ReleaseItemWrapper>
              <ReleaseInfo>
                <div>
                  <Date>{release.date}</Date>
                  <Title>{release.title}</Title>
                </div>
                <OpenIcon src={pdfIcon}/>
              </ReleaseInfo>
            </ReleaseItemWrapper>
          </a>
        ))}



      </Wrapper>
      {user && <BackToAppButton/> }
    </OuterWrapper>
  )
}

export default PressReleases

const Title = styled.h2`
  margin:0;
`

const OpenIcon = styled.img`
  width:25px;
  right:20px;
  position:absolute;
`;

const Wrapper = styled.div`
  max-width: 600px;
  margin:auto;
`;

const OuterWrapper = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  min-height:100vh;
  padding-top:120px;
`;

const ReleaseInfo = styled.div`
  display:flex;
  align-items:center;
`;

const ReleaseItemWrapper = styled.div`
  position:relative;
  background-color:white;
  padding: 15px 20px;
  border-radius:15px;
  margin-bottom:20px;
`;

const Date = styled.p`
  font-size:0.8rem;
  margin:0;
`;